import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { partnerStore } from '@/stores/partnerStore'
import { memberStore } from '@/stores/memberStore'
import { issueStore } from '@/stores/issueStore'
import { configStore } from '@/stores/configStore'
import './app.scss'
import axios from 'axios'
import globals from './globals'
import { identifyUser } from '@/services/memberService'
import chatService from '@/services/chatService'
import { chatStore } from './stores/chatStore'

// todo move this...somewhere
let url = new URL(window.location.href)
let initParam = JSON.parse(url.searchParams.get('init') || '{}')
let parentOrigin
if (
  initParam &&
  initParam.parentOrigin &&
  initParam.integrationId &&
  initParam.partnerToken &&
  initParam.partnerTeam &&
  (initParam.chatSource !== 'bot' || initParam.botURL)
) {
  parentOrigin = initParam.parentOrigin
  axios.defaults.headers.common = {
    'X-Boomtown-Token': initParam.partnerToken,
    'X-Boomtown-Date': new Date().toISOString(),
  }
  partnerStore.setConfig(initParam)
  configStore.setConfig('embedded', Boolean(initParam.parentElement))
  if (initParam.chatSource) chatStore.setChatSource(initParam.chatSource)
  if (initParam.botURL) globals.apiHostBot = initParam.botURL
} else {
  throw new Error('Missing Config Data')
}

window.addEventListener('message', event => {
  var message
  if (initParam.parentOrigin && event.origin === initParam.parentOrigin) {
    message = JSON.parse(event.data)
    switch (message.type) {
      case 'identifyUser':
        if (!chatService.client) {
          identifyUser(message.user)
        }
        break
      case 'navigateToScreen':
        if (message.path) router.push(message.path)
        if (message.name) {
          router.push({
            name: message.name,
            id: '',
            query: message.query,
          })
        }
        break
      case 'updateAppOpen':
        configStore.setConfig('appOpen', message.value)
        if (message.value === true) {
          window.sessionStorage.setItem(
            `${initParam.integrationId}.app-open`,
            1,
          )
        } else {
          window.sessionStorage.removeItem(
            `${initParam.integrationId}.app-open`,
          )
        }
        break
      case 'setHideWelcomeMessage':
        window.sessionStorage.setItem(
          `${initParam.integrationId}.welcome-hidden`,
          1,
        )
        break
      case 'updateScreenSize':
        configStore.setConfig('fullScreen', message.value < 460)
        break
      case 'resetApp':
        memberStore.unsetMember()
        issueStore.unsetIssue()
        sessionStorage.clear()
        break
      case 'sourceURL':
        configStore.setConfig('sourceURL', message.sourceURL)
        break
      default:
        break
    }
  }
})

document.addEventListener('keydown', event => {
  if (event.key === 'Escape') {
    window.parent.postMessage(
      JSON.stringify({ type: 'closeApp' }),
      partnerStore.config.parentOrigin,
    )
  }
})

globals.parentOrigin = parentOrigin

if (initParam.apiHost) {
  globals.apiHost = initParam.apiHost + '/api/v2'
} else {
  globals.apiHost = location.host.match(/goboomtown/)
    ? 'https://api' + window.location.host.split('messenger')[1] + '/api/v2'
    : 'https://api.goboomtown.com/api/v2'
}

const app = createApp(App)
app.use(router)
app.mount('#app')
