<template>
  <div class="user-identification">
    <transition name="fadeIn">
      <form
        action=""
        @submit.prevent="submitEmail"
        class="bt-form"
        v-show="showEmailForm"
      >
        <h3 class="title">Enter your email to get started</h3>
        <div class="bt-group">
          <input
            type="email"
            id="email-input"
            class="input bt-input"
            v-model="email"
            v-bind:style="inputStyles"
            v-bind:class="{ filled: email }"
            autocomplete="email"
            required
          />
          <span class="highlight"></span>
          <span class="bt-bar"></span>
          <label for="email-input" class="bt-label" v-bind:style="labelStyles"
            >Email Address</label
          >
        </div>
        <PrimaryCTA
          type="submit"
          class="primary-cta"
          :text="
            chatStore.state.chatSource === 'bot'
              ? 'Continue Conversation'
              : 'Start a Conversation'
          "
          :submitting="submittingEmail"
        ></PrimaryCTA>
      </form>
    </transition>
    <transition name="fadeIn">
      <form
        action=""
        @submit.prevent="startChat"
        class="bt-form"
        v-show="showNameForm"
      >
        <h3 class="title">Provide additional information (optional)</h3>
        <div class="bt-group">
          <input
            type="text"
            id="name-input"
            class="input bt-input"
            v-model="firstName"
            v-bind:style="inputStyles"
            v-bind:class="{ filled: firstName }"
            autocomplete="given-name"
          />
          <span class="highlight"></span>
          <span class="bt-bar"></span>
          <label for="name-input" class="bt-label" v-bind:style="labelStyles"
            >First Name</label
          >
        </div>
        <div class="bt-group">
          <input
            type="tel"
            id="phone-input"
            class="input bt-input"
            v-model="phone"
            v-bind:style="inputStyles"
            v-bind:class="{ filled: phone }"
            autocomplete="tel"
          />
          <span class="highlight"></span>
          <span class="bt-bar"></span>
          <label for="phone-input" class="bt-label" v-bind:style="labelStyles"
            >Phone Number</label
          >
        </div>

        <PrimaryCTA
          text="Continue"
          type="submit"
          class="primary-cta"
          :submitting="submittingMember"
        ></PrimaryCTA>
        <SecondaryCTA
          class="secondary-cta"
          type="button"
          text="Change Email"
          @click="changeEmail"
          :submitting="submittingMember"
        ></SecondaryCTA>
      </form>
    </transition>
  </div>
</template>

<script>
import _ from 'lodash'
import { checkUser, createMember, getMember } from '@/api/memberApi'
import { createIssue } from '@/api/issueApi'
import PrimaryCTA from './PrimaryCTA'
import SecondaryCTA from './SecondaryCTA'

export default {
  name: 'UserIdentification',
  components: { PrimaryCTA, SecondaryCTA },
  inject: [
    'chatStore',
    'themeStore',
    'memberStore',
    'issueStore',
    'notificationStore',
  ],
  data: function () {
    return {
      feedbackText: '',
      ratingValue: 0,
      email: '',
      firstName: '',
      phone: '',
      submittingEmail: false,
      submittingMember: false,
      emailSubmitted: false,
      showEmailForm: true,
      showNameForm: false,
      memberExists: false,
    }
  },
  methods: {
    submitEmail() {
      this.submittingEmail = true
      return checkUser(this.email)
        .then(memberExists => {
          this.memberExists = memberExists
        })
        .catch(error => {
          this.memberExists = false
          // allow user to continue if check-user api call fails, just create new user
          //todo: Add logging for this failure edge case
        })
        .finally(() => {
          this.showEmailForm = false
          this.showNameForm = true
          this.submittingEmail = false
          window.setTimeout(() => {
            this.focusNameInput()
          }, 0)
        })
    },
    async getOrCreateMember() {
      let member
      if (this.memberExists) {
        member = await this.getMember()
      } else {
        member = await this.createMember()
      }
      return member
    },
    createMember() {
      this.submittingMember = true
      return createMember(this.email, this.firstName, this.phone)
        .then(member => {
          this.memberStore.setMember(member)

          return member
        })
        .catch(error => {
          //todo: Add logging for this failure edge case
          this.notificationStore.addNotification({
            title: 'Error',
            message: "We've encountered an error. Please try again",
            level: 'error',
          })
          this.submittingMember = false
        })
    },
    getMember() {
      this.submittingMember = true
      return getMember(this.email, this.firstName, this.phone)
        .then(member => {
          this.memberStore.setMember(member)

          return member
        })
        .catch(error => {
          //todo: Add logging for this failure edge case
          this.notificationStore.addNotification({
            title: 'Error',
            message: "We've encountered an error. Please try again",
            level: 'error',
          })
          this.submittingMember = false
        })
    },
    createIssue() {
      return createIssue()
        .then(issue => {
          this.issueStore.setIssue(issue)
        })
        .catch(error => {
          //todo: Add logging for this failure edge case
          this.notificationStore.addNotification({
            title: 'Error',
            message: "We've encountered an error. Please try again",
            level: 'error',
          })
          this.submittingMember = false
          this.showNameForm = true
        })
    },
    async startChat() {
      let member = await this.getOrCreateMember()
      this.chatStore.setChatSource('xmpp')
      if (this.$route?.name !== 'ChatScreen') {
        this.$router.push({
          name: 'ChatScreen',
        })
        this.chatStore.setLastBotMessage()
      }
    },
    changeEmail() {
      this.showEmailForm = true
      this.showNameForm = false
      window.setTimeout(() => {
        this.focusEmailInput()
      }, 0)
    },
    focusEmailInput() {
      let emailInput = document.getElementById('email-input')
      emailInput && emailInput.focus()
    },
    focusNameInput() {
      let nameInput = document.getElementById('name-input')
      nameInput && nameInput.focus()
    },
  },
  computed: {
    inputStyles() {
      return {
        'border-color': this.themeStore.theme.primaryColor,
      }
    },
    labelStyles() {
      return {
        color: this.themeStore.theme.primaryColor,
      }
    },
  },
  mounted() {
    this.focusEmailInput()
  },
}
</script>

<style scoped lang="scss">
.user-identification {
  width: 80%;
  margin: 24px auto 24px auto;
  border-radius: 6px;
  overflow: visible;
  text-align: center;
  position: relative;
}

.title {
  text-align: center;
  font-weight: 500;
  margin-bottom: 36px;
  margin-top: 8px;
}

.bt-form {
  text-align: left;
  margin-top: 8px;
}

.input {
  // width: 100%;
  border: none;
  border-bottom: 2px solid gray;
  margin-bottom: 18px;
  border-radius: 0px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.label {
  font-size: 14px;
  text-align: left;
}

.secondary-cta,
.primary-cta {
  width: 100%;
  margin: 0px auto 18px auto;
}
.secondary-cta:disabled,
.primary-cta:disabled {
  cursor: not-allowed;
}
</style>
