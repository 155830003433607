import { reactive, computed, readonly } from 'vue'
import { chatWithImages, chatWithKB } from './data'
import CryptoJS from 'crypto-js'
import _ from 'lodash'

const state = reactive({
  messages: [],
  participants: [],
  botTyping: null,
  userTyping: null,
  chatSource: 'xmpp',
  unreadMessageCount: 0,
})

function setUserTyping(participant) {
  state.userTyping = participant
}

function setBotTyping(participant) {
  state.botTyping = participant
}

function clearUserTyping(participant) {
  state.userTyping = null
}

function clearBotTyping(participant) {
  state.botTyping = null
}

// uses context.source on a stanza to identify source bot msg
// finds corresponding action on bot message and marks as selected
function markActionSelected(parsedBody) {
  let originalMessageId = parsedBody.context.source
  let originalMessage = _.find(state.messages, message => {
    return message.stanza.id === originalMessageId
  })
  if (!originalMessage) return
  if (originalMessage.actions && Array.isArray(originalMessage.actions)) {
    let action = _.find(originalMessage.actions, action => {
      return action.lbl === parsedBody.message
    })
    if (action) {
      action.selected = true
      originalMessage.botActionSelected = true
    }
  }
}

// uses context.source on a stanza to identify source bot msg
// finds corresponding action on bot message and marks as selected
function markRatingActionSelected(parsedBody) {
  let originalMessageId = parsedBody.context.source
  let originalMessage = _.find(state.messages, message => {
    return message.stanza.id === originalMessageId
  })
  if (!originalMessage) return
  if (
    originalMessage.ratingActions &&
    Array.isArray(originalMessage.ratingActions)
  ) {
    _.each(originalMessage.ratingActions, action => {
      if (action.lbl.match(parsedBody.rating.toLowerCase())) {
        action.selected = true
        originalMessage.botRatingSelected = true
      } else {
        action.selected = false
      }
    })
  }
}

function setEmoticons(emoticons) {
  state.emoticons = emoticons
}

function addMessage(message) {
  state.messages.push(message)
}

function addParticipant(message) {
  state.participants.push(message)
}

function removeMessage(id) {
  state.messages = state.messages.filter(item => item.id !== id)
}

function removeParticipant(id) {
  state.messages = state.messages.filter(item => item.id !== id)
}

const messagesLength = computed(() => state.messages.length)

function resetChat() {
  state.messages = []
  state.participants = []
  state.unreadMessageCount = 0
}

function incrementUnreadMessageCount() {
  state.unreadMessageCount += 1
}

function clearUnreadMessageCount() {
  state.unreadMessageCount = 0
}

const endChatMessageSecret = CryptoJS.MD5('MessengerUserExit').toString()

function setChatSource(source) {
  state.chatSource = source
}

function setLastBotMessage() {
  _.last(state.messages).lastBotMessage = true
}

export const chatStore = readonly({
  state,
  messagesLength,
  endChatMessageSecret,
  markActionSelected,
  markRatingActionSelected,
  addMessage,
  removeMessage,
  addParticipant,
  removeParticipant,
  setEmoticons,
  resetChat,
  setBotTyping,
  setUserTyping,
  clearBotTyping,
  clearUserTyping,
  incrementUnreadMessageCount,
  clearUnreadMessageCount,
  setChatSource,
  setLastBotMessage,
})
