<template>
  <div
    class="message-box-wrapper"
    v-bind:class="{ 'expanded-box': expandedBox }"
  >
    <div class="files">
      <div
        class="file-upload"
        v-for="(newFile, index) in newFiles"
        :key="index"
      >
        <div
          class="progress-bar"
          v-bind:class="{
            'upload-complete': newFile.uploadPercent == 100,
          }"
        >
          <div
            class="progress-bar-indicator"
            v-bind:style="{
              right: 100 - newFile.uploadPercent + '%',
              background: themeStore.theme.primaryColor,
            }"
          >
            <span
              class="bt-icon bt-icon-check-circle"
              v-bind:class="{
                'upload-complete': newFile.uploadPercent == 100,
              }"
            ></span>
          </div>
        </div>
        <div
          class="file-name"
          v-bind:class="{
            'upload-complete': newFile.uploadPercent == 100,
          }"
        >
          {{ newFile.file.name }}
        </div>
        <button
          class="upload-cancel-button"
          :aria-label="`Remove file ${newFile.file.name}`"
          @click="removeFile(newFile)"
        >
          <span class="bt-icon bt-icon-close"></span>
        </button>
      </div>
    </div>
    <div class="message-box">
      <form
        action=""
        @submit.prevent="submitMessage"
        class="search-form"
        ref="form"
        novalidate
      >
        <textarea
          v-model="newMessageText"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="submitMessage"
          @keydown.enter.shift.exact="newline"
          name="message-input"
          class="message-input"
          id="message-input"
          placeholder="Type your message here"
          rows="5"
          :disabled="chatStore.state.chatSource == 'bot' && submittingMessage"
        ></textarea>
        <input
          name="file-input"
          type="file"
          id="file-input"
          class="file-input"
          ref="file-input"
          @change="onFileSelected"
        />
        <div class="input-buttons">
          <button
            type="button"
            class="message-button message-attachment-button"
            v-bind:class="{ disabled: submittingFileTransfer }"
            :disabled="submittingFileTransfer"
            aria-label="Select file to upload"
            @click="selectFile()"
            v-if="chatStore.state.chatSource != 'bot'"
          >
            <span class="bt-icon bt-icon-attachment"></span>
          </button>

          <button
            type="input"
            class="message-button message-send-button"
            v-bind:class="{
              'message-send-button-disabled': disableCTAs,
            }"
            aria-label="Send message"
            :disabled="disableCTAs"
          >
            <span class="bt-icon bt-icon-send-1" v-if="disableCTAs"></span>
            <span
              class="bt-icon bt-icon-send-filled"
              v-if="!disableCTAs"
            ></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import chatService from '@/services/chatService'
import { uploadFile } from '@/api/commApi'
import { PasteFileHandler } from '@/utils/PasteFileHandler'
import { sendChat } from '../api/chatDemoApi'

export default {
  name: 'MessageBox',
  inject: ['chatStore', 'memberStore', 'themeStore'],
  data: function () {
    return {
      newMessageText: '',
      sendButtonHoverColor: this.themeStore.theme.primaryHoverColor,
      sendButtonColor: this.themeStore.theme.primaryColor,
      submittingFileTransfer: false,
      submittingMessage: false,
      uploadMessageResults: [],
      newFiles: [],
      uploadController: null,
    }
  },
  methods: {
    newline() {},
    async submitBotMessage() {
      try {
        this.submittingMessage = true
        const msg = String(this.newMessageText).trim()
        this.chatStore.addMessage({
          message: msg,
          name: this.memberStore.member.guestId,
          resource: this.memberStore.member.guestId,
          avatar: '/images/user-avatar.png',
        })
        localStorage.setItem(
          'botChat',
          JSON.stringify(this.chatStore.state.messages),
        )
        this.newMessageText = ''
        this.chatStore.setBotTyping({
          name: 'Generated by OvationCXM AI',
          resource: 'ovation-bot',
          avatar: '/images/bot-avatar.png',
        })

        const response = await sendChat(msg)

        this.chatStore.addMessage({
          message: response.message,
          name: 'Generated by OvationCXM AI',
          resource: 'ovation-bot',
          avatar: '/images/bot-avatar.png',
          showUserIdentification: response.showUserIdentification,
        })
        localStorage.setItem(
          'botChat',
          JSON.stringify(this.chatStore.state.messages),
        )
        this.chatStore.clearBotTyping('bot')
        // todo: set unread message count if apppropriate
        this.submittingMessage = false
        window.setTimeout(() => {
          let input = document.getElementById('message-input')
          input && input.focus()
        }, 100)
      } catch (error) {
        this.chatStore.clearBotTyping('bot')
        this.submittingMessage = false
      }
    },
    async submitMessage() {
      if (
        (!this.newMessageText && !this.newFiles.length) ||
        this.submittingFileTransfer
      ) {
        return
      }

      if (this.chatStore.state.chatSource == 'bot') {
        return this.submitBotMessage()
      }

      if (this.newMessageText) {
        this.newMessageText = String(this.newMessageText).trim()
        chatService.sendMessage(this.newMessageText)
        this.newMessageText = ''
      }
      if (this.newFiles.length) {
        this.newFiles.forEach(file => {
          chatService.sendMessage(JSON.stringify(file.uploadResult))
        })
        this.newFiles = []
      }
    },
    selectFile() {
      this.$refs['file-input'].click()
    },
    async onFileSelected(event) {
      const files = event.target.files
      const fileReader = new FileReader()
      if (files[0]) {
        fileReader.readAsDataURL(files[0])
        const newFile = { file: files[0], uploadPercent: 5 }
        this.newFiles.push(newFile)
        this.uploadFile(newFile)
        this.$refs['file-input'].value = ''
      }
    },
    async uploadFile(newFile) {
      // todo: any validations? file type or max file size?
      try {
        newFile.uploadController = new AbortController()

        this.submittingFileTransfer = true
        let response = await uploadFile(
          chatService.commData,
          newFile.file,
          '',
          progressEvent => {
            newFile.uploadPercent = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100),
            )
          },
          newFile.uploadController,
        )
        if (response.results && Array.isArray(response.results)) {
          newFile.uploadResult = response.results[0]
        }
        this.submittingFileTransfer = false
        this.uploadController = null

        // focus on textarea after animation finishes
        window.setTimeout(() => {
          let input = document.getElementById('message-input')
          input && input.focus()
        }, 500)
      } catch (error) {
        this.submittingFileTransfer = false
        newFile.uploadPercent = 0
        this.uploadController = null
        // todo: any sort of error notification here?
      }
    },
    removeFile(newFile) {
      // cancel pending upload
      if (newFile.uploadController) {
        newFile.uploadController.abort()
        newFile.uploadController = null
      }
      this.newFiles = this.newFiles.filter(function (file) {
        return file.file !== newFile.file
      })
    },
  },
  computed: {
    expandedBox() {
      let lines = this.newMessageText.split(/\r|\r\n|\n/)

      if (this.newMessageText.length > 84) {
        return true
      } else if (lines.length == 2 && lines[1].length > 42) {
        return true
      } else if (lines.length >= 3) {
        return true
      } else {
        return false
      }
    },
    disableCTAs() {
      return (
        (!this.newMessageText && !this.newFiles.length) ||
        this.submittingFileTransfer ||
        this.submittingMessage
      )
    },
  },
  mounted() {
    var pasteFileHandler = new PasteFileHandler(
      document.getElementById('message-input'),
    )
    pasteFileHandler.on('paste-file', imageFile => {
      const newFile = { file: imageFile, uploadPercent: 5 }
      this.newFiles.push(newFile)
      this.uploadFile(newFile)
    })
    let messageInput = document.getElementById('message-input')
    messageInput && messageInput.focus()
  },
}
</script>

<style scoped lang="scss">
.message-box {
  border-top: 1px solid #dadbe0;
  position: absolute;
  height: 60px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: white;

  transition: 300ms ease height;
  .expanded-box & {
    height: 108px;
  }
  overflow: hidden;
}

.message-input {
  border: none;
  resize: none;
  padding-top: 12px;
  margin-top: 2px;
  color: #303030;
  // width: 79%;
  // font-size: 14px;
  font-size: 16px;
  transform: scale(0.875);
  transform-origin: left;
  width: 90%;

  &::placeholder {
    color: #6b737b;
  }
}

.file-input {
  display: none;
}

.message-button {
  background: transparent;
  border: none;
  font-size: 20px;
  margin-top: 14px;
  vertical-align: top;
  display: inline-block;
  color: #898d94;
  line-height: 14px;
  padding: 3px 5px;

  &:focus-visible {
    outline: double;
    outline-color: #898d94;
  }
}

.message-attachment-button:hover {
  color: #939292;
}

.message-send-button {
  margin-right: 2px;
}
.message-send-button-disabled {
  color: #898d94;
  cursor: not-allowed;
}

.bt-icon-send-filled {
  color: v-bind(sendButtonColor);

  &:hover {
    color: v-bind(sendButtonHoverColor);
  }
}

.files {
  right: 24px;
  left: 24px;
  z-index: 1;
  position: absolute;
  bottom: 60px;

  transition: 300ms ease bottom;
  .expanded-box & {
    bottom: 108px;
  }
}

.file-upload {
  background: #f8fbfb;
  height: 32px;
  color: #4f4f4f;
  overflow: hidden;
  position: relative;
  margin: 2px 0px;
  border-radius: 6px;
}

.progress-bar {
  width: 100px;
  height: 18px;
  line-height: 18px;
  position: absolute;
  left: 10px;
  top: 7px;
  border-radius: 20px;
  background: white;
  &.upload-complete {
    width: 18px;
  }
  transition: 500ms all linear;
  transition-delay: 750ms;
}

.progress-bar-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-radius: 20px;
  transition: all 250ms linear;
  border: 1px solid white;
}

.file-name {
  font-size: 14px;
  line-height: 32px;
  display: inline-block;
  margin-left: 6px;
  position: absolute;
  left: 114px;
  right: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &.upload-complete {
    left: 26px;
  }
  transition: 500ms all linear;
  transition-delay: 750ms;
}
.upload-cancel-button {
  border-radius: 6px;
  width: 20px;
  height: 20px;
  color: #4f4f4f;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  position: absolute;
  right: 6px;
  top: 6px;
  padding-left: 0;
  padding-right: 0;
  background: transparent;

  &:focus {
    outline-style: double;
    outline-color: #4f4f4f;
  }
}

.input-buttons {
  position: absolute;
  top: 0px;
  right: 0px;
}
.disabled {
  cursor: not-allowed;
}

.bt-icon-check-circle {
  color: white;
  opacity: 0;
  &.upload-complete {
    opacity: 1;
  }
  transition: 500ms all linear;
  transition-delay: 750ms;
}
</style>
